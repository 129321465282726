@keyframes flip {
    0% {
      transform: perspective(600px) rotateY(0);
    }
    50% {
      transform: perspective(600px) rotateY(180deg);
    }
    100% {
      transform: perspective(600px) rotateY(360deg);
    }
  }
  
  .animate-flip {
    animation: flip 9s linear infinite; /* Adjust duration (3s) as needed */
  }
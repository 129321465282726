/* background animate */
@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
  }
  
  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: linear-gradient(#000, #35063E, rgb(132, 82, 129));
    overflow: hidden;
    z-index: -1;
  }
  .background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
  }
  
  
  
  
  .background li:nth-child(0) {
    left: 65%;
    width: 47px;
    height: 47px;
    bottom: -97px;
    animation-delay: 1s;
  }
  .background li:nth-child(1) {
    left: 7%;
    width: 45px;
    height: 45px;
    bottom: -95px;
    animation-delay: 2s;
  }
  .background li:nth-child(2) {
    left: 41%;
    width: 85px;
    height: 85px;
    bottom: -135px;
    animation-delay: 4s;
  }
  .background li:nth-child(3) {
    left: 36%;
    width: 61px;
    height: 61px;
    bottom: -111px;
    animation-delay: 3s;
  }
  .background li:nth-child(4) {
    left: 35%;
    width: 90px;
    height: 90px;
    bottom: -140px;
    animation-delay: 3s;
  }
  .background li:nth-child(5) {
    left: 60%;
    width: 49px;
    height: 49px;
    bottom: -99px;
    animation-delay: 11s;
  }
  .background li:nth-child(6) {
    left: 74%;
    width: 54px;
    height: 54px;
    bottom: -104px;
    animation-delay: 14s;
  }
  .background li:nth-child(7) {
    left: 79%;
    width: 55px;
    height: 55px;
    bottom: -105px;
    animation-delay: 3s;
  }
  .background li:nth-child(8) {
    left: 51%;
    width: 29px;
    height: 29px;
    bottom: -79px;
    animation-delay: 6s;
  }
  .background li:nth-child(9) {
    left: 69%;
    width: 64px;
    height: 64px;
    bottom: -114px;
    animation-delay: 17s;
  }
  .background li:nth-child(10) {
    left: 71%;
    width: 34px;
    height: 34px;
    bottom: -84px;
    animation-delay: 13s;
  }
  .background li:nth-child(11) {
    left: 89%;
    width: 82px;
    height: 82px;
    bottom: -132px;
    animation-delay: 34s;
  }
  .background li:nth-child(12) {
    left: 2%;
    width: 48px;
    height: 48px;
    bottom: -98px;
    animation-delay: 6s;
  }
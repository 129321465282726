.loadingSpinner{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(46, 14, 37, 0.5);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading{
    width:  180px;
    height: 180px;
    border: 16px solid;
    border-color: #55053e transparent #e467cb transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
}